
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
    height: rem-calc(48);
    margin-bottom: rem-calc(5);

    .section-bg-beige & {
        background-color: $color-white;
        border-color: $color-white;

        &[disabled],
        &[readonly] {
            background-color: $color-gray-black-haze;
            border-color: $color-gray-black-haze;
        }
    }

    .tps-form-hollow & {
        background-color: transparent;
        border-color: $color-gray-rolling-stone;
    }

    &.input-validation-error {
        border-color: $alert-color;
    }

    &:focus,
    &:focus-visible {
        outline: rem-calc(5) auto Highlight;
        outline: rem-calc(5) auto -webkit-focus-ring-color;
    }

    &[hidden] {
        display: none;
    }

    &:-webkit-autofill {
        // Expose a hook for JavaScript when auto fill is shown.
        // JavaScript can capture 'animationstart' events
        animation-name: onAutoFillStart;
        // Make the backgound color become yellow _really slowly_
        transition: background-color 50000s ease-in-out 0s;
        animation-duration: 0.001s;
    }

    &:not(:-webkit-autofill) {
        // Expose a hook for JS onAutoFillCancel
        // JavaScript can capture 'animationstart' events
        animation-name: onAutoFillCancel;
    }
}

.has-datepicker {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M400 64h-48V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H128V12c0-6.6-5.4-12-12-12h-8c-6.6 0-12 5.4-12 12v52H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM48 96h352c8.8 0 16 7.2 16 16v48H32v-48c0-8.8 7.2-16 16-16zm352 384H48c-8.8 0-16-7.2-16-16V192h384v272c0 8.8-7.2 16-16 16zM148 320h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 96h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm-96 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm192 0h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12z"/></svg>');
    background-position: right rem-calc(15) center;
    background-repeat: no-repeat;
    background-size: rem-calc(24) auto;
    padding-right: rem-calc(60);
}

.field-validation {
    &-error {
        color: $alert-color;
    }
}

textarea {
    min-height: rem-calc(175);
}

select {
    background-image: url('data:image/svg+xml;utf8,<svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M0.6875 1L6.47559 6.22363L12 1" stroke="black" stroke-width="1.5"/></svg>');
    background-repeat: no-repeat;
    background-position: right rem-calc(-35) center;
    padding-right: rem-calc(60);

    &[readonly] {
        pointer-events: none;
    }
}

[type="radio"],
[type="checkbox"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    margin: 0;
    overflow: hidden;
    position: absolute;
    height: rem-calc(1);
    width: rem-calc(1);

    & + label:not(.switch-paddle) {
        margin-left: 0;
        position: relative;

        &:before {
            background-color: $color-white;
            border: rem-calc(1) solid $color-brand-gray-medium;
            content: "";
            display: inline-block;
            margin: rem-calc(5) rem-calc(17) rem-calc(5) 0;
            position: relative;
            height: rem-calc(22);
            width: rem-calc(22);
            vertical-align: top;
            z-index: 1;
        }
    }

    &.input-validation-error + label {
        &:before {
            border-color: $alert-color;
        }
    }

    &:focus + label,
    &:focus-visible + label {
        &:before {
            outline: rem-calc(4) auto Highlight;
            outline: rem-calc(4) auto -webkit-focus-ring-color;
        }
    }
}


[type="radio"] {
    & + label:not(.switch-paddle) {
        &:before {
            border-radius: rem-calc(11);
        }
    }

    &:checked {
        & + label {
            &:after {
                background-color: $color-brand-orange;
                border-radius: rem-calc(8);
                content: "";
                display: inline-block;
                position: absolute;
                left: rem-calc(6);
                top: rem-calc(11);
                height: rem-calc(10);
                width: rem-calc(10);
                z-index: 2;
            }
        }
    }
}

[type="checkbox"]:checked {
    & + label:not(.switch-paddle) {
        &:before {
            content: "\f00c";
            color: $color-brand-orange;
            font-family: $font-icons;
            font-size: rem-calc(14);
            line-height: rem-calc(20);
            text-align: center;
        }
    }
}

[type="submit"] {
    @extend .button;
}

.ktc-radio,
.ktc-checkbox {
    display: block;
    position: relative;
    margin-bottom: rem-calc(16.5);

    &-list {
        display: block;
        margin-top: rem-calc(16.5);
    }
}

// file upload

.ktc-file-component-wrapper,
.tps-file-component-wrapper {
    position: relative;
    display: block;
    max-width: rem-calc(385);

    label {
        background-color: $color-brand-beige;
        display: block;
        color: $color-black;
        cursor: pointer;
        margin-bottom: rem-calc(5);
        padding: rem-calc(8) rem-calc(15);
        padding-right: rem-calc(60);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:before {
            content: "\f0c6";
            font-family: $font-icons;
            display: inline-block;
            vertical-align: top;
            margin-right: rem-calc(12.5);
        }

        .section-bg-beige & {
            background-color: $color-white;
        }
    }

    [type="file"] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        margin: 0;
        overflow: hidden;
        position: absolute;
        height: rem-calc(1);
        width: rem-calc(1);
    }

    .ktc-uploader-replacement {
        display: none;
    }

    .ktc-uploader-text-button {
        position: absolute;
        right: rem-calc(23);
        top: rem-calc(16);
        display: block;
        height: rem-calc(16);
        width: rem-calc(16);
        overflow: hidden;
        text-indent: rem-calc(17);

        &:before {
            content: "\f00d";
            font-family: $font-icons;
            font-weight: $font-weight-light;
            position: absolute;
            font-size: rem-calc(20);
            top: 0;
            left: 0;
            line-height: rem-calc(16);
            text-indent: 0;
            width: rem-calc(16);
            text-align: center;
        }

        &[hidden="hidden"] {
            display: none;
        }
    }
}

// TPS Form Section styles
.tps-form {

    &-validation-summary {
        color: $color-red-alert;

        ul {
            margin: 0;
            margin-bottom: rem-calc(20);
            list-style: none;

            li {
                padding-left: 0 !important;

                &:before {
                    content: none !important;
                }
            }
        }

        &.success {
            color: $color-black;
        }
    }

    &-intro {
        margin-bottom: rem-calc(40);

        &-actions {
            margin-top: rem-calc(30);

            .button, .button-link {
                margin-right: rem-calc(10);
                vertical-align: middle;

                @include breakpoint(medium) {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-section {
        &.margin-top {
            margin-top: rem-calc(60);
        }

        &.margin-bottom {
            margin-bottom: rem-calc(60);
        }

        &.padding-top {
            padding-top: rem-calc(20);
        }

        &.padding-bottom {
            padding-bottom: rem-calc(20);
        }

        &-headline {
            font-size: rem-calc(20);
        }

        &-inner {
            margin-right: rem-calc(-8);
            margin-left: rem-calc(-8);

            @include breakpoint(large) {
                margin-right: rem-calc(-15);
                margin-left: rem-calc(-15);
            }
        }

        &-legend {
            font-size: rem-calc(16);
            font-weight: $font-weight-regular;
            padding-left: rem-calc(8);
            padding-right: rem-calc(8);

            @include breakpoint(large) {
                padding-left: rem-calc(15);
                padding-right: rem-calc(15);
            }
        }

        &-column {
            padding-left: rem-calc(8);
            padding-right: rem-calc(8);
            margin-bottom: rem-calc(30);

            @include breakpoint(large) {
                padding-left: rem-calc(15);
                padding-right: rem-calc(15);
                margin-bottom: rem-calc(37);
            }
        }
    }

    &-field-description {
        color: $color-gray-storm-dust;

        &.margin-top {
            margin-top: rem-calc(8);
        }

        &.margin-bottom {
            margin-bottom: rem-calc(8);
        }
    }

    &-footer {
        display: flex;
        align-items: center;

        .button, .button-link {
            margin-bottom: 0;
            margin-right: rem-calc(50);
        }
    }
}

.control-label {
    &.required {
        &::after {
            content: '*';
            color: $color-orange-flush;
        }
    }
}

.form-field, .editing-form-control-nested-control {
    position: relative;
}

.floating-label {
    position: absolute;
    top: rem-calc(8);
    left: rem-calc(8);
    pointer-events: none;

    input.has-value + &, select.has-value + &, input:focus + &, select:focus + & {
        opacity: 0;
    }
}

@keyframes onAutoFillStart {
    from { /**/
    }

    to { /**/
    }
}

@keyframes onAutoFillCancel {
    from { /**/
    }

    to { /**/
    }
}
